
import http from "../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch,
} from "vue";
import moment from "moment";
import router from "../router";
import { format } from "date-fns";
import Input from "common/components/Input.vue";
import func from "vue-temp/vue-editor-bridge";

export default defineComponent({
  props: ['day'],
  setup(props) {
    const form = ref({});

    const assignments = ref([]);

    const search = ref("");
    const assignment = reactive({
      name: "",
      code: "",
    });

    const order = ref({ id: 0, format: "Pallet", number: "", bundles: 0 });
    const sale = ref({ id: 0 });
    const depart = ref(new Date())

    const agents = ref([]);
    const agent_id = ref(0);
    const agent = computed(() => {
      return agents.value?.find((a: any) => a.id == agent_id.value) || {};
    });

    const customers = ref([]);
    const user_id = ref(0);
    const customer = computed(() => {
      return (
        customers.value?.find((c: any) => c.id == user_id.value) || {
          contact: { currency: "euro" },
        }
      );
    });

    const processing = ref(false);
    const created = ref(false);
    const updated = ref(false);

    const sent = ref(false);
    const sending = ref(false);

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    const date = ref(fD(new Date(props.day), "yyyy-MM-dd"));

    function getPackageSum(section = "count") {
      const data = Object.values(form.value);

      return data.reduce((o: any, n: any) => {
        return o + parseFloat(n[section]);
      }, 0);
    }

    function getFillSum(section = "count") {
      const data = Object.values(form.value);

      return data.reduce((o: any, n: any) => {
        return o + parseFloat(n[section]);
      }, 0);
    }

    function getCurrency() {
      if (customer.value.contact?.currency == "euro") {
        return "€";
      } else if (customer.value.contact?.currency == "pound") {
        return "£";
      } else if (customer.value.contact?.currency == "dollar") {
        return "$";
      } else if (customer.value.contact?.currency == "shilling") {
        return "KSh";
      }
    }

    function fetchAgents() {
      processing.value = true;
      http.get(`/api/users?role=agent&fetch=all`).then((res) => {
        agents.value = res.data;
        processing.value = false;
      });
    }

    function fetchCustomers() {
      processing.value = true;
      http.get(`/api/users?role=customer&fetch=all`).then((res) => {
        customers.value = res.data;
        processing.value = false;
      });
    }

    function fetchSale() {
      http
        .get(`/api/sales?order_id=${order.value?.id}&fetch=all`)
        .then((res: any) => {
          if (res.data[0]) {
            sale.value = res.data[0];
          }
        });
    }

    async function fetchOrders(user: any) {
      const res = await http.get(
        `/api/orders?user_id=${user}&date=${date.value}&fetch=all`
      );

      order.value = res.data[0] || {};

      fetchSale();

      return res.data;
    }

    function fetchAssignments(id: any) {
      processing.value = true;

      fetchOrders(id).then((os: any) => {
        http.get(`/api/assignments?user_id=${id}&fetch=all`).then((res) => {
          const a = res.data;
          assignments.value = a;
          const order: any = os[0];

          depart.value = new Date(order.depart)

          form.value = a.reduce(
            (r: any, p: any) => ({
              ...r,
              [p.id]: {
                count: order?.quantities ? (order?.quantities as any)[p.id] :  0,
                weight: ( order?.quantities ? (order?.quantities as any)[p.id] : 0) * ((p.weight * p.count) / 1000),
                boxes:
                  (order?.quantities ? (order?.quantities as any)[p.id] :  0) > 0
                    ? (order?.quantities as any)[p.id] /
                      (p.box_type?.pallet?.boxes || 0)
                    : 0,
                uweight: p.weight * p.count / 1000,
                cost:
                  (order?.quantities ? (order?.quantities as any)[p.id] :  0) *
                  p[customer.value?.contact?.currency || "euro"],
              },
            }),
            {}
          );

          processing.value = false;
        });
      });
    }

    function confirmOrder() {
      http
        .post("/api/sales", {
          user_id: user_id.value,
          order_id: order.value?.id,
          quantities: form.value,
          amount: getPackageSum("cost"),
          date: fD(new Date(), "yyyy-MM-dd"),
          depart: depart.value
        })
        .then((res) => {
          if (res.data.id) {
            created.value = true;
            sale.value = res.data;
          }
        })
        .finally(() => {
          fetchAssignments(user_id.value);
        });
    }

    function emailOrder() {
      sending.value = true;
      http.post(`/api/confirmation/${sale.value?.id}`).then((res) => {
        sending.value = false;
        sent.value = true
        return res;
      });
    }

    onMounted(() => {
      fetchAgents();
      fetchCustomers();

      if (router.currentRoute.value.query?.user_id) {
        const u = router.currentRoute.value.query?.user_id;

        user_id.value = Number(u);
        fetchAssignments(u);
      }
    });

    watch(
      () => date.value,
      (v: any) => {
        fetchAssignments(user_id.value);
      }
    );

    return {
      fetchAssignments,
      processing,
      sending,
      sent,
      search,
      assignments,
      confirmOrder,
      emailOrder,
      created,
      fD,
      date,
      form,
      customers,
      customer,
      user_id,
      getPackageSum,
      getCurrency,
      order,
      sale,
      agents,
      agent,
      agent_id,
      depart
    };
  },
});
